import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import {
  Col, Row, Button, Divider, Typography, Carousel, Modal, Table, Space,
  Tooltip, Steps, Drawer, Select, message
} from 'antd'
import {
  FileDoneOutlined, ExceptionOutlined, FileSyncOutlined,
  FileTextOutlined, DollarOutlined, LoadingOutlined, BarcodeOutlined,
  ArrowRightOutlined, ArrowLeftOutlined, ExclamationCircleOutlined
} from '@ant-design/icons'
import { api, BASEURL, MP_PUBLIC_KEY } from '../../../service/api'
import * as dayjs from 'dayjs'
import 'dayjs/locale/pt-br';

import '@inovua/reactdatagrid-community/index.css';
import { useNavigate } from 'react-router-dom'
import * as parceiroAction from '../../../store/actions/parceiro.action'
import { isDesktop } from 'react-device-detect';
import { BASE_URL_SITE, KEYMP } from '../../../Constantes'


const { Step } = Steps;
const { Column } = Table


const contentStyle = {
  height: '160px',
  color: '#fff',
  lineHeight: '160px',
  textAlign: 'center',
  background: '#0a0330',
};




const URL_RETORNOS = {
  success: BASE_URL_SITE.concat("Loja/conclusao"),
  failure: BASE_URL_SITE.concat("Loja/failure"),
  pending: BASE_URL_SITE.concat("Loja/failure")
}
//const NOTIFICATION_URL = BASE_URL_SITE.concat("parceiros/retornosmp")

export const DashboardPage = (props) => {

  const { parceiro, token, saveParceiro } = props
  const [vencidas, setVencidas] = useState(undefined)
  const [vencendo, setVencendo] = useState(undefined)
  //const [valorLicenca, setValorLicenca] = useState(60)
  //const [fiscalmanager, setFiscalmanager] = useState(50)
  const [prevCusto, setPrevCusto] = useState(0)
  const [saldo, setSaldo] = useState({})
  const [modalLicencas, setModalLicencas] = useState(false)
  const [dataModal, setDataModal] = useState([])
  const [rowSelecionadas, setRowSelecionadas] = useState([])
  const [totalSelecionadas, setTotalSelecionadas] = useState(0)
  const [currentStep, setCurrentStep] = useState(0)
  const [processPayment, setProcessPayment] = useState(false)

  const navigate = useNavigate()

  useEffect(() => {
    //console.log('parceiros', parceiro)
    loaddados()



  }, [])





  async function loaddados() {
    // await refresh()
    await loadLicencas(dayjs().add(-11, 'days').format('YYYY-MM-DD'),
      dayjs().add(-1, 'days').format('YYYY-MM-DD'), 0)
    await loadLicencas(dayjs().format('YYYY-MM-DD'), dayjs().add(10, 'days').format('YYYY-MM-DD'), 1)
    loadSaldo()

    

  }


  useEffect(() => {
    totalSelecionado()
    disablePagarSaldo()
  }, [rowSelecionadas])

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      // console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
      setRowSelecionadas(selectedRows)
    }
  };


  async function refresh() {
    try {
      console.log('parceiro', parceiro)
      const result = await api.post('/parceiros/refresh', parceiro,
        {
          headers: {
            'x-access-token': token,
          }
        })
      console.log('ok', result.data)
      if (result.status === 200 && result.data?.token) {
        saveParceiro({
          ...result.data,
          ...parceiro
        })

      }
    } catch (error) {
      console.log(error.message)
    }
  }




  function totalSelecionado() {
    try {
      //   console.log(parceiro)
      var temp = 0
      rowSelecionadas.map((item, i) => {
        temp += parseFloat(parceiro.valueLicenca)
        if (item?.fiscal === 1)
          temp += parseFloat(parceiro.valueFiscal)
      })
      // console.log(temp)
      setTotalSelecionadas(temp)
    } catch (error) { }
  }




  async function loadLicencas(data1, data2, tipo) {
    try {
      console.log('loadLicencas')
      var response = await api.get('parceiros/licencas2', {
        headers: {
          'x-access-token': token,
        },
        params: {
          data1: data1,
          data2: data2,
          tipo: tipo
        }
      })
      response.data.map((item, i) => {
        item.name = item.fantasia
        item.key = i
      })
      //  console.log('DATA', response.data)
      if (tipo === 0) {
        //  console.log('libcencas', response.data)
        setVencidas(response.data)
      }
      else {
        //  console.log('libcencas', response.data)
        setVencendo(response.data)
        let prev = 0
        if (response.data.length > 0)
          response.data.map((item, i) => {
            // console.log(item)
            prev = prev + parseFloat(parceiro?.valueLicenca)
            if (item.fiscal === 1)
              prev = prev + parseFloat(parceiro?.valueFiscal)
          })
        setPrevCusto(prev)
      }

    } catch (error) {
      if (error?.response.status === 401) {
        saveParceiro(undefined)
        navigate('/parceiros/login')
      } else { }
      console.log(error)
    }
  }


  async function loadSaldo() {
    try {
      var response = await api.get('parceiros/saldo', {
        headers: {
          'x-access-token': token,
        }
      })
      setSaldo(response.data)
      //  console.log('Saldo', response.data)
    } catch (error) {
      // console.log(error.message)
      message.error('Erro ao carregar saldo!')
    }
  }


  function disablePagarSaldo() {
    try {
      var aux = true
      //rowSelecionadas.length === 0
      if (parseFloat(saldo?.saldo) >= parseFloat(totalSelecionadas))
        aux = false
    } catch (error) {
      console.log(error.message)
    }
    // console.log('aux', aux)
    return aux;
  }

  async function pagarComSaldo() {
    try {
      setProcessPayment(true)
      var response = await api.post('parceiro/credito/pagar', rowSelecionadas, { headers: { 'x-access-token': token } })
      message.success('Solicitação realizada com sucesso!')
      setModalLicencas(false)
      loadSaldo()
      loadLicencas(dayjs().add(-11, 'days').format('YYYY-MM-DD'),
        dayjs().add(-1, 'days').format('YYYY-MM-DD'), 0)
      loadLicencas(dayjs().format('YYYY-MM-DD'), dayjs().add(10, 'days').format('YYYY-MM-DD'), 1)

    } catch (error) {
      message.error('Erro ao processar solicitação!')
    }
    setProcessPayment(false)
  }


  function renderFoot() {
    switch (currentStep) {
      case 0:
        return <div style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginTop: 10 }}>
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%' }}>
            <Typography.Text>{rowSelecionadas.length}</Typography.Text>
            <div style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              width: '100%',
              alignItems: 'center',
              fontSize: 12
            }}>
              {"Selecionado: R$ ".concat(totalSelecionadas)}
            </div>
          </div>
          <Space>
            <Button type='primary' disabled={rowSelecionadas.length === 0} icon={<ArrowRightOutlined />}
              onClick={() => setCurrentStep(1)}>Próximo</Button>
          </Space>
        </div>

      case 1:
        return <div style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginTop: 10 }}>
          {isDesktop && <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%' }}>
            <Typography.Text>{rowSelecionadas.length}</Typography.Text>
          </div>
          }
          {
            isDesktop ? <Space>
              <Button type='default' disabled={rowSelecionadas.length === 0} icon={<ArrowLeftOutlined />}
                onClick={() => {
                  setCurrentStep(0)
                  setRowSelecionadas([])
                }}>Voltar</Button>

              {

                <Button type='primary'
                  style={{ minWidth: 220 }}
                  loading={processPayment}
                  disabled={disablePagarSaldo()}
                  icon={<DollarOutlined />}
                  onClick={() => {
                    Modal.confirm({
                      title: 'Atenção!',
                      icon: <ExclamationCircleOutlined />,
                      content: 'Confirmar pagamento da(s) licença(s) selecionada(s) com seu saldo?',
                      okText: 'Sim',
                      cancelText: 'Não',
                      onOk: () => {
                        pagarComSaldo()
                        // console.log('clicou')
                      }
                    })
                  }}>{"Pagar com saldo de R$ ".concat(saldo?.saldo)}
                </Button>

              }


              <Button
                style={{ minWidth: 220 }}
                type='primary' loading={processPayment} disabled={rowSelecionadas.length === 0}
                icon={<BarcodeOutlined />}
                onClick={() => irPagamento()}>{"Gerar cobrança R$ ".concat(totalSelecionadas)}</Button>
            </Space>
              :
              <Space style={{ width: '100%' }} direction="vertical">

                <Button block type='default' disabled={rowSelecionadas.length === 0} icon={<ArrowLeftOutlined />}
                  onClick={() => {
                    setCurrentStep(0)
                    setRowSelecionadas([])
                  }}>Voltar</Button>


                <Button type='primary' block
                  style={{ minWidth: 220 }}
                  loading={processPayment}
                  disabled={disablePagarSaldo()}
                  icon={<DollarOutlined />}
                  onClick={() => {
                    Modal.confirm({
                      title: 'Atenção!',
                      icon: <ExclamationCircleOutlined />,
                      content: 'Confirmar pagamento da(s) licença(s) selecionada(s) com seu saldo?',
                      okText: 'Sim',
                      cancelText: 'Não',
                      onOk: () => {
                        pagarComSaldo()
                      }
                    })
                  }}>{"Pagar com saldo de R$ ".concat(saldo?.saldo)}
                </Button>

                <Button block
                  style={{ minWidth: 220 }}
                  type='primary' loading={processPayment} disabled={rowSelecionadas.length === 0}
                  icon={<BarcodeOutlined />}
                  onClick={() => irPagamento()}>{"Gerar cobrança R$ ".concat(totalSelecionadas)}</Button>

              </Space>
          }

        </div>
    }
  }


  async function irPagamento() {
    try {
      setProcessPayment(true)
      var transacao = {
        token: parceiro.email,
        value: totalSelecionadas,
        itens: []
      }
      rowSelecionadas.map((item, index) => {
        transacao.itens.push({
          item_descricao: 'Plano Mensal',
          item_code: "P1",
          item_valor: item.valueLicenca,
          item_token: item.email
        })

        if (item.fiscal === 1) {
          transacao.itens.push({
            item_descricao: 'Fiscal NFCe',
            item_code: "F1",
            item_valor: item.valueFiscal,
            item_token: item.email
          })
        }
      })



      const response = await api.post('loja/payment/parceiro', transacao, {
        headers: {
          'x-access-token': token,
        }
      })

      transacao.id = response.data.id
      // console.log("transacao", transacao, parceiro)

      // ------------------------------------------------------------------------------
      // ------------------------------------------------------------------------------
      // INICIA MERCADOPAGO -----------------------------------------------------------
      // ------------------------------------------------------------------------------
      var mercadopago = require('mercadopago')
      mercadopago.configure({
        access_token: KEYMP
      });

      var preference = {
        auto_return: "approved"
      }
      var itens = []
      for (const item of transacao.itens) {
        itens.push({
          title: item.item_descricao,
          unit_price: parseFloat(item.item_valor),
          quantity: 1,
          currency_id: 'BRL',
          id: item.item_code
        })
      }

      var name_sobrename = parceiro.nome.trim().split(' ');
      var telefones = ['', '']
      if (parceiro.fone1) {
        var telefones = parceiro.fone1.trim().split(' ')
        telefones[0] = telefones[0].replace('(', '').replace(')', '').replace('-', '')
        if (telefones.length > 1)
          telefones[1] = telefones[1].replace('(', '').replace(')', '').replace('-', '')
        else
          telefones[1] = telefones[0].replace('-', '')
      }

      preference.payment_methods = {
        installments: 1,
        default_installments: 1
      }

      preference.items = itens;
      preference.payer = {
        name: name_sobrename[0],
        surname: name_sobrename.length > 1 ? name_sobrename[1] : name_sobrename[0],
        email: parceiro.email,
        phone: {
          area_code: telefones[0],
          number: parseInt(telefones[1])
        },

        identification: {
          type: "CPF",
          number: parceiro?.cpf.replace('.', '').replace('-', '')
        },

        address: {
          zip_code: parceiro.cep ? parceiro.cep?.replace('-', '') : parceiro.cep?.replace('-', ''),
          street_name: parceiro.endereco,
          // street_number: dataToken[0].numero
        }
      }

      preference.back_urls = URL_RETORNOS
      preference.notification_url = BASEURL.concat("notification/retornosmp") //NOTIFICATION_URL
      preference.external_reference = response.data.id.toString()


      try {

        let initPref = await api.post('/parceiros/preference', preference, {
          headers: { 'x-access-token': token }
        })

        var url = "https://programaengfood.com.br/notificationsite/init/preference?uid=".concat(initPref.data.uid)
        const link = document.createElement('a');
        link.href = url;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        //  const preferenceCreate = await mercadopago.preferences.create(preference);
        //  console.log('URL: ', `${preferenceCreate.body.init_point}`)
        //  navigate(`${preferenceCreate.body.init_point}`);

      } catch (err) {
        console.log('Erro na inicialização das configurações de pagamento!\n'.concat(err.message))
        message.error('Erro na inicialização das configurações de pagamento!\n'.concat(err.message));
      }


      // const preferenceCreate = await mercadopago.preferences.create(preference);
      // navigate(response.redirect(`${preferenceCreate.body.init_point}`));

    } catch (error) {
      console.log(error.message)
      message.error(error.message)
    }
    setProcessPayment(false)
  }


  function renderStep() {
    switch (currentStep) {

      case 0:
        return <Table
          rowSelection={{
            type: 'checkbox',
            ...rowSelection,
          }}
          style={{ marginTop: -15, textTransform: "uppercase" }}
          dataSource={dataModal}
          size="small"
          pagination={false}>
          <Column title={isDesktop ? <strong style={{ textTransform: 'uppercase' }}>Fantasia</strong> : ''} dataIndex={"fantasia"} key={"fantasia"}
            render={(text, record, index) => {
              return isDesktop ? <div style={{ fontSize: 11 }}><span style={{ color: 'royalblue', fontWeight: 'bold' }}>{text}</span>
                {record.fiscal === 1 &&
                  <Tooltip title="Cliente emite Cupom Fiscal" color={'cyan'} key={'cyan'}>
                    <img src={require('../../../images/nfce.png')} height={18} />
                  </Tooltip>
                }
              </div> : <div style={{ fontSize: 11 }}>
                <div><strong> {text} </strong></div>
                <div> {record.email} </div>
                <div>Vencimento:  {dayjs(record.data2).format("DD/MM/YYYY")} </div>
              </div>

            }} />

          {isDesktop && <Column title={<strong>Cidade</strong>} dataIndex={"cidade"} key={"cidade"}
            render={(text, record, index) => {
              return <div style={{ fontSize: 11 }}>{text}</div>

            }} />
          }


          {isDesktop && <Column title={<strong>E-mail</strong>} dataIndex={"tok_email"} key={"tok_email"}
            render={(text, record, index) => {
              return <div style={{ fontSize: 11 }}>{text}</div>

            }} />
          }

          {isDesktop && <Column title={<strong>Vencimento</strong>} dataIndex={"data2"} key={"tok_email"}
            render={(text, record, index) => {
              return <div style={{ fontSize: 11 }}>{dayjs(text).format('DD/MM/YYYY')}</div>

            }} />
          }
        </Table>



      //PAGAMENTO
      case 1:
        return <Table showHeader={isDesktop}
          style={{ marginTop: -15, textTransform: "uppercase" }}
          dataSource={rowSelecionadas}
          size="small"
          pagination={false}>
          <Column title={isDesktop ? <strong>Fantasia</strong> : ''} dataIndex={"fantasia"} key={"fantasia"}
            render={(text, record, index) => {
              return isDesktop ? <div style={{ fontSize: 11 }}><span style={{ color: 'royalblue', fontWeight: 'bold', textTransform: 'uppercase' }}>{text}</span> {record.fiscal === 1 &&
                <Tooltip title="Cliente emite Cupom Fiscal" color={'cyan'} key={'cyan'}>
                  <img src={require('../../../images/nfce.png')} height={18} />
                </Tooltip>
              }
              </div> : <div style={{ fontSize: 11 }}>
                <div><strong> {text} </strong></div>
                <div> {record.email} </div>
                <div>Vencimento:  {dayjs(record.data2).format("DD/MM/YYYY")} </div>
                <div>
                  <Select size='small' value={record.fiscal} style={{ minWidth: 200, fontSize: 11 }}
                    onChange={(value) => {
                      console.log(record)
                      record.fiscal = value
                      rowSelecionadas[index] = record
                      setRowSelecionadas([...rowSelecionadas])
                    }}>
                    <Select.Option value={0} style={{ fontSize: 11 }}>Não emitir</Select.Option>
                    <Select.Option value={1} style={{ fontSize: 11 }}>{'Emitir (+ '.concat(parseFloat(parceiro.valueFiscal).toFixed(2), ' reais)')} </Select.Option>
                  </Select>
                </div>
                <div>
                  <div style={{ fontSize: 12, fontWeight: 'bold' }}>{
                    "R$ ".concat(parseFloat(record.valueLicenca) + (record.fiscal === 1 ? parseFloat(record.valueFiscal) : 0))
                  }</div>
                </div>
              </div>

            }} />

          {isDesktop && <Column title={<strong>Cidade</strong>} dataIndex={"cidade"} key={"cidade"}
            render={(text, record, index) => {
              return <span style={{ fontSize: 11 }}>{text}</span>
            }}
          />
          }

          {isDesktop && <Column title={<strong>E-mail</strong>} dataIndex={"email"} key={"email"}
            render={(text, record, index) => {
              return <span style={{ fontSize: 11 }}>{text}</span>
            }}
          />
          }

          {isDesktop && <Column title={<strong>NFC-e</strong>} dataIndex={"fantasia"} key={"fantasia"} width={200}
            render={(text, record, index) => {
              return <Select size='small' value={record.fiscal} style={{ width: '100%', fontSize: 11 }}
                onChange={(value) => {
                  console.log(record)
                  record.fiscal = value
                  rowSelecionadas[index] = record
                  setRowSelecionadas([...rowSelecionadas])
                }}>
                <Select.Option value={0} style={{ fontSize: 11 }}>Não emitir</Select.Option>
                <Select.Option value={1} style={{ fontSize: 11 }}>{'Emitir (+ '.concat(parseFloat(parceiro.valueFiscal).toFixed(2), ' reais)')} </Select.Option>
              </Select>
            }}
          />
          }

          {isDesktop && <Column title={<strong>Total</strong>} dataIndex={"email"} key={"email"} width={100}
            render={(text, record, index) => {
              return <div style={{ fontSize: 12, textAlign: 'right', width: '100%', fontWeight: 'bold' }}>{
                "R$ ".concat(parseFloat(record.valueLicenca) + (record.fiscal === 1 ? parseFloat(record.valueFiscal) : 0))
              }</div>
            }}
          />
          }
        </Table>




    }
  }



  return (
    <div>
      <Row gutter={[36, 24]}>
        <Col span={24}>
          <Carousel>
            <div>
              <h3 style={contentStyle}>Painel da Revenda</h3>
            </div>
          </Carousel>
        </Col>
        <Col xs={24} lg={12} xl={8}>
          <div className='dashTicket' onClick={() => {
            // props.setStatus('Todos')
            //  props.setKey('2')
            navigate("/parceiros/clientes")
          }}>
            <div className='dashTicket-item1'>
              <FileTextOutlined style={{ color: 'white', fontSize: 36 }} />
            </div>
            <div className='dashTicket-item2'>
              <div><strong>Total de Contratos</strong></div>
              <Divider style={{ margin: 2, marginLeft: -2 }} />
              <div><Typography.Title level={3} style={{ marginTop: 5, marginLeft: 5, marginBottom: 0 }}>
                {parceiro?.clientes?.length}
              </Typography.Title></div>
              <div style={{ fontSize: 11 }}>Clientes</div>
            </div>
          </div>
        </Col>
        <Col xs={24} lg={12} xl={8}>
          <div className='dashTicket' onClick={() => {
            //  props.setStatus('Ativo')
            // props.setKey('2')
            navigate("/parceiros/clientes", { status: "Ativos", key: '2' })
          }}>
            <div className='dashTicket-item1'>
              <FileDoneOutlined style={{ color: 'white', fontSize: 36 }} />
            </div>
            <div className='dashTicket-item2'>
              <div><strong>Contratos ativos</strong></div>
              <Divider style={{ margin: 2, marginLeft: -2 }} />
              <div><Typography.Title level={3} style={{ marginTop: 5, marginLeft: 5, marginBottom: 0 }}>
                {parceiro?.clientes?.filter((item) => item.situacao === 'Ativo').length}
              </Typography.Title></div>
              <div style={{ fontSize: 11 }}>Clientes</div>
            </div>
          </div>
        </Col>
        <Col xs={24} lg={12} xl={8}>
          <div className='dashTicket' onClick={() => {
            setDataModal(vencendo)
            setModalLicencas(true)
          }}>
            <div className='dashTicket-item1'>
              <FileSyncOutlined style={{ color: 'white', fontSize: 36 }} />
            </div>
            <div className='dashTicket-item2'>
              <div><strong>Licenças vencendo</strong></div>
              <Divider style={{ margin: 2, marginLeft: -2 }} />
              <div><Typography.Title level={3} style={{ marginTop: 5, marginLeft: 5, marginBottom: 0 }}>
                {
                  vencendo ? vencendo.length : <LoadingOutlined />
                }
              </Typography.Title></div>
              <div style={{ fontSize: 11 }}>próximos 10 dias</div>
            </div>
          </div>
        </Col>
        <Col xs={24} lg={12} xl={8}>
          <div className='dashTicket' onClick={() => {
            setDataModal(vencidas)
            setModalLicencas(true)
          }}>
            <div className='dashTicket-item1'>
              <ExceptionOutlined style={{ color: 'white', fontSize: 36 }} />
            </div>
            <div className='dashTicket-item2'>
              <div><strong>Licenças vencidas</strong></div>
              <Divider style={{ margin: 2, marginLeft: -2 }} />
              <div><Typography.Title level={3} style={{ marginTop: 5, marginLeft: 5, marginBottom: 0 }}>
                {
                  vencidas ? vencidas.length : <LoadingOutlined />
                }
              </Typography.Title>
              </div>
              <div style={{ fontSize: 11 }}>Últimos 10 dias</div>
            </div>
          </div>
        </Col>

        <Col xs={24} lg={12} xl={8}>
          <div className='dashTicket' onClick={() => {

          }}>
            <div className='dashTicket-item1'>
              <DollarOutlined style={{ color: 'white', fontSize: 36 }} />
            </div>
            <div className='dashTicket-item2'>
              <div><strong>Previsão de custos</strong></div>
              <Divider style={{ margin: 2, marginLeft: -2 }} />
              <div><Typography.Title level={3} style={{ marginTop: 5, marginLeft: 5, marginBottom: 0 }}>
                {
                  "R$ ".concat(prevCusto?.toFixed(2))
                }
              </Typography.Title></div>
              <div style={{ fontSize: 11 }}>próximos 10 dias</div>
            </div>
          </div>
        </Col>
        <Col xs={24} lg={12} xl={8}>
          <div className='dashTicket' onClick={() => {
            // props.setKey('7')
          }}>
            <div className='dashTicket-item1'>
              <DollarOutlined style={{ color: 'white', fontSize: 36 }} />
            </div>
            <div className='dashTicket-item2'>
              <div><strong>Seu saldo</strong></div>
              <Divider style={{ margin: 2, marginLeft: -2 }} />
              <div><Typography.Title level={3} style={{ marginTop: 5, marginLeft: 5, marginBottom: 0 }}>
                {
                  "R$ ".concat(saldo?.saldo)
                }
              </Typography.Title></div>
              <div style={{ fontSize: 11 }}>R$</div>
            </div>
          </div>
        </Col>
      </Row>

      {
        /*
      <Modal
        bodyStyle={{ minHeight: 400 }}
        afterClose={() => {
          setRowSelecionadas([])
          setCurrentStep(0)
        }}
        width={'90%'}
        title={<Steps size="small" current={currentStep} style={{ marginRight: 80 }}>
          <Step title="Licenças" />
          <Step title="Pagamento" />
          <Step title="Finalizar" style={{ marginRight: 50 }} />
        </Steps>}
        visible={modalLicencas}
        destroyOnClose={true}
        onOk={() => setModalLicencas(false)}
        onCancel={() => setModalLicencas(false)}
        footer={null}>
        {
          renderStep()
        }
      </Modal>
        */
      }


      <Drawer
        onClose={() => {
          setRowSelecionadas([])
          setCurrentStep(0)
          setModalLicencas(false)
        }}
        width={'100%'}
        title={<Steps size="small" current={currentStep} style={{ marginRight: 80 }}>
          <Step title="Licenças" />
          <Step title="Configurações" style={{ marginRight: 50 }} />
        </Steps>} placement="right"
        open={modalLicencas}
        footer={
          renderFoot()
        }>
        {
          renderStep()
        }
      </Drawer>


    </div >
  )

}

const mapStateToProps = (state) => ({
  parceiro: state.parceiroReducer.parceiro?.parceiro,
  token: state.parceiroReducer.parceiro?.token
})

const mapDispatchToProps = dispatch => ({
  saveParceiro: (parceiro) => dispatch(parceiroAction.save_parceiro(parceiro)),
})

export default connect(mapStateToProps, mapDispatchToProps)(DashboardPage)